import Loadable from "components/Loadable";
import NotFound from "components/notFound";
import { lazy } from "react";
import Pages from "store/pages";
import CommunityConfig from "views/pages/community";
import About from "views/pages/company/about";
import Certificate from "views/pages/company/certificate";
import Location from "views/pages/company/location";
import Point from "views/pages/company/point";
import Youtube from "views/pages/company/youtube";
import MnGallery from "views/pages/outsourcing/mn/gallery";
import MnStaff from "views/pages/outsourcing/mn/staff";
import Portfolio from "views/pages/portfolio/portfolio";
import Kakao from "views/pages/solution/kakao";
import OutsourcingSolution from "views/pages/solution/outsourcing";
import Rcs from "views/pages/solution/rcs";
import Sms from "views/pages/solution/sms";

const Main = Loadable(lazy(() => import("views/pages/main/main")));
const DynamicPage = Loadable(lazy(() => import("views/pages/dynamic/page")));
const GalleryDetail = Loadable(lazy(() => import("views/pages/outsourcing/galleryDetail")));

const MainRoutes = [
    // MAIN
    { path: Pages.MAIN, element: <Main /> },

    // COSTUMER SERVICE
    { path: "", children: [...CommunityConfig.routes] },
    { path: "/web/contents/:id", element: <DynamicPage /> },

    // STATIC PAGES
    { path: "/mn/outsourcing/staff", element: <MnStaff /> },
    { path: "/staffs", element: <MnStaff /> },
    { path: "/mn/outsourcing/gallery", element: <MnGallery /> },
    { path: "/mn/outsourcing/gallery/:id", element: <GalleryDetail /> },

    // PORTFOLIO
    { path: "/portfolio", element: <Portfolio /> },

    // SOLUTION
    { path: "/solution/sms", element: <Sms /> },
    { path: "/solution/kakao", element: <Kakao /> },
    { path: "/solution/rcs", element: <Rcs /> },
    { path: "/solution/outsourcing", element: <OutsourcingSolution /> },

    // COMPANY
    { path: "/about", element: <About /> },
    { path: "/point", element: <Point /> },
    { path: "/location", element: <Location /> },
    { path: "/company/certificates", element: <Certificate /> },
    { path: "/company/youtube", element: <Youtube /> },

    // 404 Not Found 페이지
    { path: "*", element: <NotFound /> }
];

export default MainRoutes;
