import { MainConfig, CommunityConfig, BannerConfig } from "views/pages";

import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { SolutionApi } from "api/solution";

export const Middlewares = [
    MainConfig.API.middleware,
    CommunityConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    SolutionApi.middleware,
    BannerApi.middleware
];
