// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* .qna-create-form {
    padding: 10px 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
/* .css-1wac9fm-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    border: none;
    box-sizing: none;
    padding: 20px 14px;
} */
/* .css-3vb4xk-MuiGrid-root {
    box-sizing: border-box;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 40px 0;
    padding: 10px 150px;
} */

/* .qna-title-input {
    padding: 20px;
} */

/* .answer-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} */
.phoneNumber {
    display: flex;
    gap: 15px;
    height: 38px;
    align-items: center;
}



/* .errorMsg {
    color: red;
    display: flex;
    margin-left: 14.5%;
}

.qna_textarea {
    padding: 10px;
    height: 201px;
    width: 100%;
    border: 1px solid #767676;
}
.css-1gclfwn-MuiGrid-root {
    border-left: 0;
} */


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/qna-view.css"],"names":[],"mappings":";AACA;;;;;;;GAOG;AACH;;;;GAIG;AACH;;;;;;;GAOG;;AAEH;;GAEG;;AAEH;;;;GAIG;AACH;IACI,aAAa;IACb,SAAS;IACT,YAAY;IACZ,mBAAmB;AACvB;;;;AAIA;;;;;;;;;;;;;;GAcG;;;AAGH,gCAAgC;AAChC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,0BAA0B;AAC9B","sourcesContent":["\n/* .qna-create-form {\n    padding: 10px 100px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n} */\n/* .css-1wac9fm-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {\n    border: none;\n    box-sizing: none;\n    padding: 20px 14px;\n} */\n/* .css-3vb4xk-MuiGrid-root {\n    box-sizing: border-box;\n    -webkit-flex-direction: row;\n    -ms-flex-direction: row;\n    flex-direction: row;\n    margin: 40px 0;\n    padding: 10px 150px;\n} */\n\n/* .qna-title-input {\n    padding: 20px;\n} */\n\n/* .answer-info {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n} */\n.phoneNumber {\n    display: flex;\n    gap: 15px;\n    height: 38px;\n    align-items: center;\n}\n\n\n\n/* .errorMsg {\n    color: red;\n    display: flex;\n    margin-left: 14.5%;\n}\n\n.qna_textarea {\n    padding: 10px;\n    height: 201px;\n    width: 100%;\n    border: 1px solid #767676;\n}\n.css-1gclfwn-MuiGrid-root {\n    border-left: 0;\n} */\n\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n    -moz-appearance: textfield;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
