
// icon
import smsIcon from "assets/images/icon/sms_icon.png";
import solIcon from "assets/images/icon/sol_icon.png";
import solSettIcon from "assets/images/icon/sol_sett_icon.png";
import { useTranslation } from "react-i18next";

export default function Info() {
    const { t } = useTranslation();

    return (
        <div className="solution_footer">
            <h5>{t('guide_text')}</h5> 
            <article className="sub-container">
                <div className="item">
                    <div className="icon">
                        <img src={smsIcon} alt="sms solution tagoplus" />
                    </div>
                    <h6>{t('design_program')}</h6>
                    <p>
                        {t('design_text')}
                    </p>
                    <span>
                        {t('contact')} : 070-8244-5807 <br></br> {t('email')} : admin@tagoplus.co.kr
                    </span>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={solIcon} alt="sms solution tagoplus" />
                    </div>
                    <h6>{t('solution_pay')}</h6>
                    <p>
                        {t('upgrade_text')}
                    </p>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={solSettIcon} alt="sms solution tagoplus" />
                    </div>
                    <h6>{t('upgrade')}</h6>
                    <p>
                        {t('upgrade_text')}
                    </p>
                </div>
            </article>
        </div>
    );
}
