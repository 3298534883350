import React, { useState, lazy, useEffect } from "react";
import Routes from "routes";
import { useLocation } from "react-router";
import ReactGA from 'react-ga4';
import i18n from "i18n";
import { StyleSheetManager } from "styled-components";
import { SERVER_URL } from "config";
import themes from "themes";
import { ThemeProvider } from "@mui/material/styles";
import isPropValid from "@emotion/is-prop-valid";
import Loader from "components/Loader";
import Loadable from "components/Loadable";
import { useGetMenusQuery, useGetSiteInfoQuery } from "api/main/main";
import ScrollToTop from "utils/scrolltoTop";
import LocaleContext from "LocaleContext";
import "./assets/css/index.css";
import "./assets/css/media.css";
import "./assets/css/qna-view.css";

const Header = Loadable(lazy(() => import("views/layout/header")));
const Footer = Loadable(lazy(() => import("views/layout/footer")));
const SubMenu = Loadable(lazy(() => import("views/layout/subMenu")));

ReactGA.initialize('G-NQRJFQ2NTF');

function App() {
    const location = useLocation();
    const [locale, setLocale] = useState(i18n.language);
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const { data: menuData, menuLoading } = useGetMenusQuery();

    useEffect(() => {
        if (siteInfo) {
            let favIcon = siteInfo?.content?.atchFav?.url;
            document.title = siteInfo?.content?.siteNm;

            let domain = window.location.hostname;
            // console.log(domain);
            if (domain == "www.itwizard.mn") {
                i18n.changeLanguage("en").then((r) => {});
            } else {
                i18n.changeLanguage("kr").then((r) => {});
            }

            if (favIcon) {
                let favLink = document.createElement("link");
                let head = document.getElementsByTagName("head")[0];
                favLink.id = "favicon";
                favLink.type = "image/x-icon";
                favLink.rel = "icon";
                favLink.href = SERVER_URL + favIcon;
                head.appendChild(favLink);
            }
        }
    }, [siteInfo]);

    useEffect(() => {
        let filter = menuData?.data.filter(menu => menu.menuUrl === location.pathname);
        if(filter?.length > 0) 
            document.title = (locale === 'kr') ? filter[0].menuNameKr : filter[0].menuName;
        else 
            document.title = (locale === 'kr') ? "tagoplus" : "(주)타고플러스";

        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
                <ThemeProvider theme={themes()}>
                    {(menuLoading || loading) && <Loader />}
                    <Header menuData={menuData} menuLoading={menuLoading} siteInfo={siteInfo?.content || null} />
                    {location.pathname != "/" && menuData ? <SubMenu menuData={menuData} location={location} /> : ""}
                    <ScrollToTop>
                        <Routes />
                    </ScrollToTop>
                    {siteInfo?.content && <Footer data={siteInfo?.content} />}
                </ThemeProvider>
            </StyleSheetManager>
        </LocaleContext.Provider>
    );
}

export default App;
