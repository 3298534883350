import React from 'react';

import bg_path from "../../../assets/images/path.png"
import bg_intro from "../../../assets/images/intro_bg.png"
import map from "../../../assets/images/03.png"

export default function About() {
    return (
        <div className="conts">
            <div className="cont_wrap">
                <div className="company_introduction1 container">
                    <div className="com_intro ">
                        <h3>인터넷 비스니스의 파트너!</h3>
                        <h2>해외 IT 사업의 선두주자 타고플러스</h2>
                        <p><strong>(주)타고플러스는</strong> 웹기반의 각 종 <strong>솔루션 및 어플리케이션 개발,<br/>
                            인터넷 마케팅, 그룹웨어 서비스에서 개발까지</strong> 인터넷상의 다양한 업무들을 수행하고 있습니다.<br/>
                            또 한, 수많은 <strong>해외 프로젝트 수행 및 많은 해외 IT 아웃소싱 사업을 성공적으로 수행</strong>했습니다.</p>
                    </div>
                    <div className="com_chart">
                        <img src={bg_path} alt=''/>
                        <div className="chart_inn">
                            <div className="chart_txt_wrap">
                                <h5 className="rdchart_ttl">자체적인 브랜드</h5>
                                <p className="rdchart_desc">자체적인 브랜드 BIZWIZ(Business+Wizard) <br/>시리즈를 개발하여 출시하고
                                    있습니다. <br/>국내뿐만 아니라 일본과
                                    인도네시아 까지도 <br/>그 기술력 및 성능을 인정받고 있습니다.</p>
                            </div>
                        </div>
                        <div className="chart_inn">
                            <div className="chart_txt_wrap">
                                <h5 className="rdchart_ttl">웹 표준, 웹 접근성</h5>
                                <p className="rdchart_desc">기업의 얼굴이라고 할수도 있는 <br/>홈페이지 분야에서도 다년간의 경험으로 <br/>모바일웹, 웹표준,
                                    웹접근성 등을 <br/>이용한 많은
                                    프로젝트를 수행한 바 있습니다</p>
                            </div>
                        </div>
                        <div className="chart_inn">
                            <div className="chart_txt_wrap">
                                <h5 className="rdchart_ttl">웹 기술의 선진화</h5>
                                <p className="rdchart_desc">향후 타고플러스에서는 21세기 인터넷의 발전과 <br/>디지털 산업의 활성화를 목적으로 인터넷
                                    인프라 <br/>구상을 위한 전반적 시스템
                                    수립에 신기술을 타<br/>기업보다 앞서 진행을 하고 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="company_introduction2">
                    <img src={bg_intro} alt=''/>
                    <div className="intro_txt_wrap container">
                        <h1>타고플러스(他故+Plus)의 의미처럼</h1>
                        <p>남들과는 다른 사고로 창조적이고 독창적으로 한 발 더 나아가 IT사업의 주도적인 업체가<br/> 되기 위해서 최선을 다하고 앞으로도 노력하는 기업, 신뢰있는 기업이
                            되기
                            위해 열심히 뛰겠습니다.</p>
                    </div>
                </div>
                <div className="company_introduction3 container">
                    <div className="table_wrap">
                        <div className="com_info">
                            <div>
                                <p className="info_ttl">법인명</p>
                                <p className="info_desc"> (주)타고플러스</p>
                                <p className="info_ttl">사업자등록번호</p>
                                <p className="info_desc">113-86-13483</p>
                                <p className="info_ttl">통신판매신고번호</p>
                                <p className="info_desc">구로 2008-00086호</p>
                            </div>
                            <div>
                                <p className="info_ttl">대표이사</p>
                                <p className="info_desc">문양희</p>
                                <p className="info_ttl">업태</p>
                                <p className="info_desc">서비스업, 소매업</p>
                                <p className="info_ttl">임직원 수</p>
                                <p className="info_desc">70명</p>
                            </div>
                            <div>
                                <p className="info_ttl">종목</p>
                                <p className="info_desc">서비스업, 소매업</p>
                                <p className="info_ttl"> 대표전화 / 팩스</p>
                                <p className="info_desc">1644-5805 / 02-6919-1793</p>
                                <p className="info_ttl">대표메일</p>
                                <p className="info_desc">admin@tagoplus.co.kr</p>
                            </div>
                            <div>
                                <p className="info_ttl">본 점 소재지</p>
                                <p className="info_desc">서울 금천구 가산디지털1로 5 대륭테크노타운20차 402호</p>
                            </div>
                            <div>
                                <p className="info_ttl">몽골지사</p>
                                <p className="info_desc">
                                    ITwizard LLC <br/> #1104-1105, 47/1 apartment, Seoul street, 2nd khoroo, Bayangol
                                    district,
                                    Ulaanbaatar city, Mongolia<br/>
                                    Homepage : http://www.itwizard.mn
                                </p>
                                <p className="info_ttl">설립일</p>
                                <p className="info_desc">
                                    2003년 11월 1일 아이티위즈 창업 <br/>2007년 5월 8일 (주)타고플러스로 법인 전환
                                    <br/>2021년 3월15일 몽골 ITwizard LLC 현지 법인 창업
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="company_introduction4">
                    <img src={map} alt=''/>
                </div>
            </div>
        </div>
    )
}