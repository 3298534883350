import { Button, Modal } from "@mui/material";
import Subcard from "components/card/subcard";
import ytIcon from "assets/images/sub/youtube_play.png";
import React, { useState } from "react";
import { useYoutubeListQuery } from "api/community/community";
import { SERVER_URL } from "config";
import { CustomSubmitButton } from "components/buttons/CustomButton";
import Loader from "components/Loader";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "10px",
    width: "800px",
    height: "500px",
    outline: "none",
    "& .MuiCardContent-root": {
        height: "100%"
    }
};

export default function Youtube() {
    const { data, isLoading, isFetching, refetch } = useYoutubeListQuery();
    const [select, setSelect] = useState(null);
    const [open, setOpen] = useState(false);
    const [showCount, setShowCount] = useState(12);

    const handleYoutubeOpen = (e) => {
        setSelect(e.ytLink);
        setOpen(true);
    };

    const handleLoadMore = () => {
        setShowCount((prevCount) => prevCount + 8);
    };

    return (
        <div className="sub-container">
            {isLoading && <Loader />}
            <div className="sub_conts_inn">
                <div className="top_video">
                    <iframe
                        src="https://www.youtube.com/embed/JjdNGtORUjo?si=jPFQBrYC0uveaB5F&autoplay=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>
                <a href="https://www.youtube.com/@itwizardtv" className="youtubeLink" target="_blank" rel="noopener noreferrer">
                    <img src={ytIcon} alt="tagoplus youtube chanel" />
                    <span>유튜브 바로 가기</span>
                </a>
                <ul className="sub_videos cursor-pointer">
                    {data &&
                        data?.slice(0, showCount).map((item, i) => (
                            <li key={i} onClick={() => handleYoutubeOpen(item)}>
                                <img src={SERVER_URL + (item.attachments[0]?.url || "")} alt={item.ytTitle} />
                                <p>{item.ytTitle}</p>
                            </li>
                        ))}
                </ul>
                {showCount < data?.length && (
                    <div style={{ marginTop: "80px", display: "flex", justifyContent: "center" }}>
                        <CustomSubmitButton onClick={handleLoadMore} text="더 보기" />
                    </div>
                )}
            </div>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Subcard sx={style} className="youtubeModalInn">
                    <iframe
                        src={select ? `https://www.youtube.com/embed/${select}?autoplay=1` : ""}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        style={{ width: "100%", height: "100%" }}
                    />
                </Subcard>
            </Modal>
        </div>
    );
}
