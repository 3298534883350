import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full my-28 flex flex-col column justify-center items-center">
            <div>
                <h1 className="text-5xl font-semibold tracking-tighter sm:text-9xl">
                    4<span className="">😥</span>4
                </h1>
            </div>
            <p className="mt-8 font-light text-lg sm:text-3xl ">{t("not_found_page")}</p>
            <Link to="/" className="mt-5 bg-black text-pink-50 py-2 px-5 rounded-full">
                {t("go_to_home_page")}
            </Link>
        </div>
    );
};

export default NotFound;
