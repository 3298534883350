// import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import circle from "../../../assets/images/subs/circle.png";
import clock from "../../../assets/images/subs/clock.png";
import computer from "../../../assets/images/subs/computer.png";
import money from "../../../assets/images/subs/money.png";
import pen from "../../../assets/images/subs/pen.png";

export default function Point() {
    const { t } = useTranslation();
    return (
        <div className="sub-container">
            <div className="sub_conts_inn">
                <div className="point_cont1">
                    <div className="point_box point_box1">
                        <img src={money} alt="money-bag" />
                        <div className="point_innercont1">
                            <h3>{t('cost_reduction')}</h3>
                            <p>
                                {t('point_sub')}
                            </p>
                        </div>
                    </div>
                    <div className="point_box point_box2">
                        <img src={pen} alt="holding-pen" />
                        <div className="point_innercont2">
                            <h3>{t('point_sub_title')}</h3>
                            <p>
                                {t('point_2nd_description')}
                            </p>
                        </div>
                    </div>
                    <div className="point_box point_box3">
                        <img src={clock} alt="clock" />
                        <div className="point_innercont3">
                            <h3>{t('time_reduction')}</h3>
                            <p>
                                {t('text_3rd')}
                            </p>
                        </div>
                    </div>
                    <div className="point_box point_box4">
                        <img src={computer} alt="computer" />
                        <div className="point_innercont4">
                            <h3>{t('resource')}</h3>
                            <p>{t('point_3rd_description')}</p>
                        </div>
                    </div>
                    <div className="circle_cont">
                        <div className="inner-circle">
                            <img src={circle} alt="circle" className="circle" />
                            <p>
                                {t('outsourcing_abc')}
                                <br />
                                {t('benefit')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="point_cont2">
                    <h4>
                        {t('provide_services')}
                        <span>{t('text_m')} </span>{t('text_n')} <span>{t('text_o')}</span>
                        <br />
                        <span>{t('text_p')}</span>
                    </h4>
                </div>
            </div>
        </div>
    );
}
